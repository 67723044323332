import React, { useEffect, useState } from "react";
import { Card, Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { successMessage } from "../../utils/alertMessage";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { addRemarksType, updateRemarksType } from "../../services/remarksType";
import { Dropdown } from "primereact/dropdown";

const AddRemarksType = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const [input, setInput] = useState({
    type: state?.type || "",
  });

  const [errors, setErrors] = useState({
    type: "",
  });

    const [remarksTypeStatus, setRemarksTypeStatus] = useState({
      name: "True",
      value: true,
    });

    const statusDropdown = [
      {
        name: "True",
        value: true,
      },
      {
        name: "False",
        value: false,
      },
    ];

    
  const handleStatusChange = (e) => {
    setRemarksTypeStatus(e.value);
  };

  useEffect(() => {
    if (state !== null) {
      setRemarksTypeStatus({
        name: state.status ? "True" : "False",
        value: state.status,
      }); // Set initial status from state
    }
  }, [state]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (input.type === "") {
      newErrors.type = "Please Fill Remarks Type";
      isValid = false;
    } else {
      newErrors.type = "";
    }
    // Add more validation logic for other fields as needed

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    setSubmitButtonPressed(true);
    event.preventDefault();
    if (validateForm()) {
      if (state === null) {
        const data = {
          type: input.type,
        };
        addRemarksType(data)
          .then((response) => {
            if (!response.data.error) {
              const Res = response.data;
              successMessage("Remark Type Added Successfully");
              setSubmitButtonPressed(false);
              navigate("/view-remarks-type");
            }
          })
          .catch((err) => {
            console.error("Error:", err);
          });
      } else {
        const data = {
            id:state.id,
          type: input.type,
          status: remarksTypeStatus.value,
        };
        updateRemarksType(data)
          .then((response) => {
            if (!response.data.error) {
              const Res = response.data;
              successMessage("Remark Type Updated Successfully");
              setSubmitButtonPressed(false);
              navigate("/view-remarks-type");
            }
          })
          .catch((err) => {
            console.error("Error:", err);
          });
      }
    }
  };
  return (
    <div>
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row>
          <Col style={{ margin: 10 }}>
            <div style={{ textAlign: "center" }}>
              <h3>{state !== null ? "Edit" : "Add"} Remark Type</h3>
            </div>
            <main id="main" className="main">
              <section className="section">
                <div className="row">
                  <div className="col-lg-12">
                    <Card
                      style={{
                        backgroundColor: "#1752AA",
                        color: "white",
                        marginTop: 20,
                      }}
                    >
                      <Card.Body>
                        <form onSubmit={handleSubmit}>
                          <div className="row mb-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="remarkType"
                                className="form-label"
                              >
                                Remark Type
                              </label>
                            </div>
                            <InputText
                              type="text"
                              style={{ width: "100%" }}
                              id="type"
                              name="type"
                              value={input.type}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  type: e.target.value,
                                });
                              }}
                              readonly
                            />
                            <div className="text-danger mt-1">
                              {errors.type}
                            </div>
                          </div>
                          {state !== null && (
                            <div>
                              <div className="row mb-3">
                                <div className="col-md-12">
                                  <label
                                    htmlFor="qrCodeCount"
                                    className="form-label"
                                  >
                                    Garment Status
                                  </label>
                                </div>
                                <Dropdown
                                  value={remarksTypeStatus}
                                  options={statusDropdown}
                                  optionLabel="name"
                                  onChange={handleStatusChange}
                                  placeholder="Select a Status"
                                />
                                <div className="text-danger mt-1">
                                  {errors.qrCodeCount}
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="col-md-12 text-center">
                            <button
                              type="submit"
                              className="move-btn-a move-btn-mu"
                              disabled={submitButtonPressed}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </section>
            </main>
          </Col>
          <Col style={{ textAlign: "center", marginTop: 50 }}>
            <img src="/Img/5.png" alt="logo" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AddRemarksType;
