import axios from "axios";
import { CONSUMER_BASE_URL } from "./BaseURL";
import getHeaders from "../constants/headers";

export const brandLocalityUpload = (brandId, uploadFile) => {
  const formData = new FormData();
  formData.append("uploadFile", uploadFile); // Assuming 'file' is the file object you want to upload

  return axios({
    method: "post",
    url:
      CONSUMER_BASE_URL + "/api/secure/v1/brand-locality/" + brandId + "/upload-list",
    data: formData,
    headers: getHeaders("multipart/form-data"),
  });
};
