import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";

import {
  addActivateDeactivateUser,
  getBrandUsers,
} from "../../services/brandApi";
import { formatDate } from "../../utils/DateUtil";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";

const ConfigUser = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  const brandId = state;

  const [loading, setLoading] = useState(false);
  const [brandUserList, setBrandUserList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState({});

  const [displayDialog, setDisplayDialog] = useState(false);
  const [nextRenewalOn, setNextRenewalOn] = useState(new Date());
  const [lastRenewalOn, setLastRenewalOn] = useState(new Date());
  const [minNextRenewalOn,setMinNextRenewalOn] = useState(new Date());

  const fetchAllBrands = () => {
    setLoading(true);
    getBrandUsers(brandId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          console.log("Res", Res);
          setBrandUserList(Res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchAllBrands();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value); // Update searchQuery state with the input value
  };

  // const handleCheckboxChange = (item) => {
  //   let id = item.id
  //   setSelectedUserData(item);
  //   setSelectedUserIds((prevSelectedUserIds) => {
  //     if (prevSelectedUserIds.includes(id)) {
  //       return prevSelectedUserIds.filter((userId) => userId !== id);
  //     } else {
  //       return [...prevSelectedUserIds, id];
  //     }
  //   });
  // };

  const handleCheckboxChange = (item) => {
    let id = item.id;

    setSelectedUserData(item); // Set the selected user data
    // Set the currently selected userId, removing previous selection
    setSelectedUserIds((prevSelectedUserIds) => {
      if (prevSelectedUserIds.includes(id)) {
        return []; // Deselect if already selected
      } else {
        return [id]; // Select only the new one
      }
    })

    if (new Date(item.nextRenewalOn) <= new Date()) {
      setNextRenewalOn(new Date());
    } 
    else {
      setNextRenewalOn(new Date(item.nextRenewalOn));
    setMinNextRenewalOn(new Date(item.nextRenewalOn));
    }
  };

  // Function to filter orderData based on search query

  const filteredData = brandUserList
    .filter((item) => {
      const query = searchQuery.toLowerCase();
      return (
        item.firstName.toLowerCase().includes(query) ||
        item.mobileNo.toLowerCase().includes(query) ||
        item.email.toLowerCase().includes(query) ||
        item.role.toLowerCase().includes(query) ||
        item.userLoginId.toLowerCase().includes(query)
      );
    })
    .sort((a, b) => {
      const nameA = a.firstName.toLowerCase();
      const nameB = b.firstName.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

  const onClickDialog = (data) => {
    setDisplayDialog(true);
  };

  const onHideDialog = () => {
    setNextRenewalOn(new Date());
    setLastRenewalOn(new Date());
    setMinNextRenewalOn(new Date());
    setDisplayDialog(false);
  };

  const renderDialogDialog = () => (
    <Dialog
      header="Activate"
      visible={displayDialog}
      onHide={() => onHideDialog()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <form onSubmit={(e) => handleActivateDeActivate(e, true)}>
          <div className="row mb-3">
            <div className="col-md-12">
              <h4>
                User Name :{" "}
                {selectedUserData.firstName + " " + selectedUserData.lastName}
              </h4>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="LastRenewalOn" className="form-label">
                Renewal On
              </label>
              <Calendar
                id="LastRenewalOn"
                name="LastRenewalOn"
                value={lastRenewalOn}
                style={{ width: "100%" }}
                onChange={(e) => {
                  setLastRenewalOn(e.target.value);
                }}
                showIcon
                maxDate={new Date()}
                disabled={true}
              />
            </div>
            <div className="col-md-8">
              <label htmlFor="NextRenewalOn" className="form-label">
                Next Renewal On
              </label>
              <Calendar
                id="NextRenewalOn"
                name="NextRenewalOn"
                value={nextRenewalOn}
                style={{ width: "100%", height: 300 }}
                onChange={(e) => {
                  const utcDate = new Date(
                    Date.UTC(
                      e.target.value.getFullYear(),
                      e.target.value.getMonth(),
                      e.target.value.getDate()
                    )
                  );
                  setNextRenewalOn(utcDate);
                }}
                showIcon
                // minDate={minNextRenewalOn}
                minDate={new Date(lastRenewalOn)}
                inline
              />
            </div>
          </div>
          <div className="col-md-12 text-center">
            <button type="submit" className="move-btn move-btn-su">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );

  const handleActivateDeActivate = async (event, value) => {
    event.preventDefault();

    let activateData = {
      userIds: selectedUserIds,
      nextRenewalOn: nextRenewalOn.toISOString(),
      lastRenewalOn: lastRenewalOn,
      renewed: value,
    };
    let deActivateData = {
      userIds: selectedUserIds,
      renewed: value,
    };

    const data = value ? activateData : deActivateData;
    addActivateDeactivateUser(brandId, data)
      .then((response) => {
        if (!response.data.error) {
          if (response.data.success === false) {
            errorMessage(response.data.message);
          } else {
            successMessage(response.data.message);
            fetchAllBrands();
            onHideDialog();
            setSelectedUserIds([]);
            setNextRenewalOn("");
            setLastRenewalOn(new Date());
            setMinNextRenewalOn(new Date());
          }
        } else {
          errorMessage(response.data.errors.errors[0].message);
        }
      })
      .catch((err) => {
        errorMessage();
      });
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row className="mt-3 ml-3">
          <Col>
            <InputText
              type="text"
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Search User..."
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            {selectedUserIds.length > 0 && (
              <React.Fragment>
                <button
                  type="button"
                  className="move-btn-a move-btn-mu center-text"
                  onClick={() => onClickDialog(true, "displayDialog")}
                  variant="primary"
                  style={{
                    backgroundColor: "#2b70d7", // Change background color if selected
                    color: "white", // Change text color if selected
                    textAlign: "center",
                    width: 120,
                  }}
                >
                  Activate User
                </button>
                <button
                  type="button"
                  className="move-btn-a move-btn-mu center-text"
                  onClick={(e) => handleActivateDeActivate(e, false)}
                  variant="primary"
                  style={{
                    backgroundColor: "#2b70d7", // Change background color if selected
                    color: "white", // Change text color if selected
                    textAlign: "center",
                    width: 120,
                  }}
                >
                  DeActivate Users
                </button>
              </React.Fragment>
            )}
            <button
              type="button"
              className="move-btn-a move-btn-mu center-text"
              onClick={() =>
                navigate("/add-admin-user", {
                  state: { brandId: brandId, edit: null },
                })
              }
              variant="primary"
              style={{
                backgroundColor: "#2b70d7", // Change background color if selected
                color: "white", // Change text color if selected
                textAlign: "center",
                width: 120,
              }}
            >
              Add User
            </button>
          </Col>
        </Row>
        <Row>
          <Col style={{ margin: 10 }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ color: "blue", fontSize: "20px" }}
                  ></i>{" "}
                  User List is Loading
                </h3>
              </div>
            ) : filteredData.length > 0 ? (
              <div>
                <div style={{ textAlign: "center" }}>
                  <h3>User List </h3>
                </div>
                <main id="main" className="main mb-3">
                  <section className="section">
                    <div className="col-lg-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>User Name</th>
                            <th>Mobile No</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>User Login Id</th>
                            <th>Status</th>
                            <th>Created On</th>
                            <th>Next Renewal On</th>
                            <th>Edit</th>
                            <th>Select</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.firstName + " " + item.lastName}</td>
                              <td>{item.mobileNo}</td>
                              <td>{item.email}</td>
                              <td>{item.role}</td>
                              <td>{item.userLoginId}</td>
                              <td>
                                {item.enabled ? (
                                  <i
                                    className="pi pi-check"
                                    style={{ color: "green", fontSize: "20px" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="pi pi-times"
                                    style={{ color: "red", fontSize: "20px" }}
                                  ></i>
                                )}
                              </td>
                              <td>{formatDate(item.createdDate)}</td>
                              <td>
                                {item.nextRenewalOn &&
                                  formatDate(item.nextRenewalOn)}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    navigate("/add-admin-user", {
                                      state: { brandId: brandId, edit: item },
                                    })
                                  }
                                >
                                  <i
                                    className="pi pi-pencil"
                                    style={{ color: "blue", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedUserIds.includes(item.id)}
                                  onChange={() => handleCheckboxChange(item)}
                                  style={{ width: 25, height: 25 }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </main>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>No User Found</h3>
              </div>
            )}
          </Col>
        </Row>
        <div>{renderDialogDialog()}</div>
      </Container>
    </div>
  );
};

export default ConfigUser;
