import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row, Button } from "react-bootstrap";
import "../index.css";
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { getAllBrands } from "../../services/brandApi";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { getBrandStore } from "../../services/regenerate";
import { brandCustomerUpload } from "../../services/BrandCustomerUpload";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { brandLocalityUpload } from "../../services/BrandLocalityUpload";

const AllBrandList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [brandStoreList, setBrandStoreList] = useState([]);
  const [mapWithStore, setMapWithStore] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState("");

  const fetchAllBrands = () => {
    setLoading(true);
    getAllBrands()
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          setBrandList(Res);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchAllBrands();
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value); // Update searchQuery state with the input value
  };
  // Function to filter orderData based on search query

  const filteredData = brandList.filter((item) => {
    const query = searchQuery.toLowerCase();
    return (
      item.name.toLowerCase().includes(query) ||
      item.mobileNo.toLowerCase().includes(query) ||
      item.email.toLowerCase().includes(query) ||
      item.brandCode.toLowerCase().includes(query)
    );
  });

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileName(file ? file.name : ""); // Update the file name or clear it if no file is selected

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  const handleFileLocalityChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setFileNameLocality(file ? file.name : ""); // Update the file name or clear it if no file is selected

    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImageLocality(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    } else {
      setSelectedImageLocality(null);
    }
  };


  const [fileName, setFileName] = useState("");
  const [fileNameLocality, setFileNameLocality] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageLocality, setSelectedImageLocality] = useState(null);
  const [displayCustomerUpload, setDisplayCustomerUpload] = useState(false);
  const [displayLocalityUpload, setDisplayLocalityUpload] = useState(false);
    
  const [submitButtonPressed, setSubmitButtonPressed] = useState(false);

  const onClickCustomerUpload = (item) => {
    setDisplayCustomerUpload(true);
    setSelectedBrandId(item.id);
    getBrandStore(item.id)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const getBrandStore = Res.map((item, index) => ({
            name: item.storeName,
            value: item.id,
          }));
          setBrandStoreList(getBrandStore);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };
  const onHideCustomerUpload = () => {
    setFileName("");
    setSelectedImage(null);
    setDisplayCustomerUpload(false);
  };

   const onClickLocalityUpload = (item) => {
    setSelectedBrandId(item.id);
     setDisplayLocalityUpload(true);
   };
   const onHideLocalityUpload = () => {
     setFileNameLocality("");
     setSelectedImageLocality(null);
     setDisplayLocalityUpload(false);
   };

  const renderDialogCustomerUpload = () => (
    <Dialog
      header="Customer Upload"
      visible={displayCustomerUpload}
      onHide={() => onHideCustomerUpload()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <form onSubmit={handleCustomerUpload}>
          <div className="row mb-3">
            <div className="col-md-12">
              <label htmlFor="qrCodeCount" className="form-label">
                Store Selection
              </label>
            </div>
            <Dropdown
              value={mapWithStore}
              options={brandStoreList}
              optionLabel="name"
              onChange={(e) => {
                setMapWithStore(e.target.value);
              }}
              placeholder="Select a Store"
            />
          </div>
          <div className="row mb-3">
            <div
              className="col-md-12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <label for="file-upload" className="custom-file-upload">
                  <i className="pi pi-upload"></i> Choose Customer File
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </div>
              <div>
                <i
                  className="pi pi-file"
                  style={{ color: "blue", fontSize: "40px" }}
                ></i>
                {fileName && (
                  <p
                    style={{
                      alignSelf: "center",
                      fontSize: 14,
                      fontWeight: 600,
                      margin: 10,
                    }}
                  >
                    {fileName}
                  </p>
                )}
              </div>
            </div>
          </div>
          {selectedImage && (
            <div className="col-md-12 text-center">
              <button
                type="submit"
                className="move-btn move-btn-su"
                disabled={submitButtonPressed}
              >
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    </Dialog>
  );

  const renderDialogLocalityUpload = () => (
    <Dialog
      header="Locality Upload"
      visible={displayLocalityUpload}
      onHide={() => onHideLocalityUpload()}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
    >
      <div>
        <form onSubmit={handleLocalityUpload}>
          <div className="row mb-3">
            <div
              className="col-md-12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>
                <label for="file-upload" className="custom-file-upload">
                  <i className="pi pi-upload"></i> Choose Locality File
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileLocalityChange}
                  style={{ display: "none" }}
                />
              </div>
              <div>
                <i
                  className="pi pi-file"
                  style={{ color: "blue", fontSize: "40px" }}
                ></i>
                {fileNameLocality && (
                  <p
                    style={{
                      alignSelf: "center",
                      fontSize: 14,
                      fontWeight: 600,
                      margin: 10,
                    }}
                  >
                    {fileNameLocality}
                  </p>
                )}
              </div>
            </div>
          </div>
          {selectedImageLocality && (
            <div className="col-md-12 text-center">
              <button
                type="submit"
                className="move-btn move-btn-su"
                disabled={submitButtonPressed}
              >
                Submit
              </button>
            </div>
          )}
        </form>
      </div>
    </Dialog>
  );

  const handleCustomerUpload = (event) => {
    event.preventDefault();
    const fileInput = event.target.querySelector('input[type="file"]');
    const uploadFile = fileInput.files[0];

    if (!uploadFile) {
      console.error("No file selected");
      return;
    }

    brandCustomerUpload(selectedBrandId, uploadFile, mapWithStore.value)
      .then((response) => {
        // handle the response
        successMessage("Customer Upload Successfully");
        setMapWithStore("");
        setSelectedBrandId("");
        setDisplayCustomerUpload(false);
      })
      .catch((error) => {
        // handle errors
        errorMessage("Customer Upload Failed");
        setMapWithStore("");
        setSelectedBrandId("");
        setDisplayCustomerUpload(false);
      });
  };

   const handleLocalityUpload = (event) => {
     event.preventDefault();
     const fileInput = event.target.querySelector('input[type="file"]');
     const uploadFile = fileInput.files[0];

     if (!uploadFile) {
       console.error("No file selected");
       return;
     }

     brandLocalityUpload(selectedBrandId, uploadFile)
       .then((response) => {
         // handle the response
         successMessage("Locality Upload Successfully");
         setSelectedBrandId("");
         setDisplayLocalityUpload(false);
       })
       .catch((error) => {
         // handle errors
         errorMessage("Locality Upload Failed");
         setSelectedBrandId("");
         setDisplayLocalityUpload(false);
       });
   };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <Row className="mt-3 ml-3">
          <Col>
            <InputText
              type="text"
              value={searchQuery}
              onChange={handleSearchInputChange}
              placeholder="Search Brand..."
            />
          </Col>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Link
              to="/add-brand"
              className="move-btn-a move-btn-mu center-text"
              variant="primary"
              style={{
                backgroundColor: "#2b70d7", // Change background color if selected
                color: "white", // Change text color if selected
                textAlign: "center",
                width: 120,
              }}
            >
              Add Brand
            </Link>
          </Col>
        </Row>
        <Row>
          <Col style={{ margin: 10 }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ color: "blue", fontSize: "20px" }}
                  ></i>{" "}
                  Brand List is Loading
                </h3>
              </div>
            ) : filteredData.length > 0 ? (
              <div>
                <div style={{ textAlign: "center" }}>
                  <h3>Brand List </h3>
                </div>
                <main id="main" className="main mb-3">
                  <section className="section">
                    <div className="col-lg-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>S. No.</th>
                            <th>Id</th>
                            <th>Brand Code</th>
                            <th>Name</th>
                            <th>Mobile No</th>
                            <th>Email</th>
                            <th>View Details</th>
                            <th>Edit</th>
                            <th>Config User</th>
                            <th>Customer Upload</th>
                            <th>Locality Upload</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.id}</td>
                              <td>{item.brandCode}</td>
                              <td>{item.name}</td>
                              <td>{item.mobileNo}</td>
                              <td>{item.email}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    navigate("/view-brand-details", {
                                      state: item,
                                    })
                                  }
                                >
                                  <i
                                    className="pi pi-file"
                                    style={{ color: "green", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    navigate("/add-brand", {
                                      state: item,
                                    })
                                  }
                                >
                                  <i
                                    className="pi pi-pencil"
                                    style={{ color: "green", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    navigate("/config-user", {
                                      state: item.id,
                                    })
                                  }
                                >
                                  <i
                                    className="pi pi-cog"
                                    style={{ color: "blue", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    onClickCustomerUpload(
                                      item,
                                      "displayCustomerUpload"
                                    )
                                  }
                                >
                                  <i
                                    className="pi pi-upload"
                                    style={{ color: "blue", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  className="btn"
                                  onClick={() =>
                                    onClickLocalityUpload(
                                      item,
                                      "displayLocalityUpload"
                                    )
                                  }
                                >
                                  <i
                                    className="pi pi-upload"
                                    style={{ color: "blue", fontSize: "20px" }}
                                  ></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </section>
                </main>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3>No Brand Found</h3>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      <div>{renderDialogCustomerUpload()}</div>
      <div>{renderDialogLocalityUpload()}</div>
    </div>
  );
};

export default AllBrandList;
