import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import "../index.css";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { errorMessage, successMessage } from "../../utils/alertMessage";
import { validateFullName, validatePhone, validEmail } from "./Regex";
import {
  addAdminUser,
  getAllBrandRole,
  updateAdminUser,
} from "../../services/brandApi";

const AddAdminUser = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  console.log("state", state);
  const brandId = state.brandId;
  const editData = state.edit;

  const [input, setInput] = useState({
    firstName: editData?.firstName || "",
    lastName: editData?.lastName || "",
    mobileNo: editData?.mobileNo || "",
    email: editData?.email || "",
    password: editData?.password || "",
    role: editData?.role || null,
    // admin: true
  });
  const [error, setError] = useState({
    firstName: "",
    mobileNo: "",
    email: "",
    password: "",
  });

  const [roleDropdown, setRoleDropdown] = useState();
  const fetchAllBrandRoles = () => {
    getAllBrandRole(brandId)
      .then((response) => {
        if (!response.data.error) {
          const Res = response.data;
          const roleData = Res.map((item) => ({
            label: item.role,
            value: item.role,
          }));
          setRoleDropdown(roleData);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
      });
  };

  useEffect(() => {
    fetchAllBrandRoles();
  }, []);

  // const roleDropdown = [
  //   { label: "ROLE_ADMIN", value: "ROLE_ADMIN" },
  //   { label: "ROLE_STORE", value: "ROLE_STORE" },
  //   { label: "ROLE_RIDER", value: "ROLE_RIDER" },
  //   { label: "ROLE_WAREHOUSE", value: "ROLE_WAREHOUSE" },
  // ];

  const validateForm = () => {
    let isValid = true;
    const newError = { ...error };

    if (!validateFullName.test(input.firstName)) {
      newError.firstName =
        "First Name can not be empty, it should be greater then 2 alphabets ";
      isValid = false;
    } else {
      newError.name = "";
    }
    // if (!validatePhone.test(input.mobileNo)) {
    //   newError.mobileNo = "Please Enter Valid 10 Digit Mobile Number";
    //   isValid = false;
    // } else {
    //   newError.mobileNo = "";
    // }

    // if (!validEmail.test(input.email)) {
    //   newError.email = "Please Enter Valid Email Address";
    //   isValid = false;
    // } else {
    //   newError.email = "";
    // }
    if (input.password === "") {
      newError.password = "Please Enter Valid Password";
      isValid = false;
    } else {
      newError.password = "";
    }

    setError(newError);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      let data = {
        brandId: brandId,
        email: input.email,
        mobileNo: input.mobileNo,
        password: input.password,
        firstName: input.firstName,
        lastName: input.lastName,
        role: input.role.value,
        admin: input.role.value === "ROLE_ADMIN" ? true : false,
      };

      const updateData = {
        ...state.edit,
         brandId: brandId,
        email: input.email,
        mobileNo: input.mobileNo,
        firstName: input.firstName,
        lastName: input.lastName,                 
        role: editData?.role,
        admin: editData?.admin,
        id: editData?.id,
      };

      console.log("updateData", updateData);

      if (editData !== null) {
        updateAdminUser(brandId, updateData)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/config-user", {
                  state: brandId,
                });
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      } else {
        addAdminUser(brandId, data)
          .then((response) => {
            if (!response.data.error) {
              if (response.data.success === false) {
                errorMessage(response.data.message);
              } else {
                successMessage(response.data.message);
                navigate("/config-user", {
                  state: brandId,
                });
              }
            } else {
              // errorMessage(response.data.errors.errors[0].message);
            }
          })
          .catch((err) => {
            errorMessage();
          });
      }
    }
  };

  return (
    <div className="form-demo background-image-login">
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage: "linear-gradient(to bottom, #1752aa, #2b70d7)",
        }}
      >
        <Container>
          <Button
            style={{ background: "none", border: "none", left: -20 }}
            onClick={() => navigate(-1)}
          >
            <i className="pi pi-arrow-left" style={{ fontSize: "2rem" }}></i>
          </Button>
          <Navbar.Brand href="/">
            <img style={{ width: "46px" }} src="/Img/1.png" alt="logo" />
          </Navbar.Brand>

          <Navbar.Toggle />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="me-auto-new">
              <Nav.Link className="nav-link-design" href="/">
                Home
              </Nav.Link>
              <Nav.Link className="nav-link-design" href="/logout">
                Logout
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid>
        <div className="mt-3" style={{ textAlign: "center" }}>
          <h3>{editData !== null ? "Edit" : "Create"} User</h3>
        </div>
        <main id="main" className="main">
          <section className="section">
            <div className="row">
              <div className="col-lg-12">
                <div
                  style={{
                    margin: 20,
                    backgroundColor: "#fff",
                    paddingTop: 10,
                    paddingBottom: 1,
                    paddingLeft: 20,
                    paddingRight: 20,
                    borderRadius: 20,
                    cursor: "pointer",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    transition: "0.3s",
                    marginBottom: 15,
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firstName" className="form-label">
                          First Name
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="firstName"
                          name="firstName"
                          value={input.firstName}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              firstName: e.target.value,
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.firstName}</small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastName" className="form-label">
                          Last Name
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="lastName"
                          name="lastName"
                          value={input.lastName}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              lastName: e.target.value,
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.lastName}</small>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="mobileNo" className="form-label">
                          Mobile No
                        </label>
                        <InputText
                          type="number"
                          style={{ width: "100%" }}
                          id="mobileNo"
                          name="mobileNo"
                          value={input.mobileNo}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              mobileNo: e.target.value,
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.mobileNo}</small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="email" className="form-label">
                          Email Id
                        </label>
                        <InputText
                          type="text"
                          style={{ width: "100%" }}
                          id="email"
                          name="email"
                          value={input.email}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              email: e.target.value.replace(/ /g, ", "),
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.email}</small>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <InputText
                          type="number"
                          style={{ width: "100%" }}
                          id="password"
                          name="password"
                          value={input.password}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              password: e.target.value,
                            });
                          }}
                          // required
                        />
                        <div className="text-danger">
                          <small>{error.password}</small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="role" className="form-label">
                          Role
                        </label>

                        {editData?.role ? (
                          <InputText
                            type="text"
                            style={{ width: "100%" }}
                            id="editTole"
                            name="editTole"
                            value={editData?.role}
                            readOnly
                          />
                        ) : (
                          <Dropdown
                            value={input.role}
                            options={roleDropdown}
                            optionLabel="label"
                            onChange={(e) => {
                              setInput({
                                ...input,
                                role: e.value,
                              });
                            }}
                            placeholder="Select a Role"
                            style={{ width: "100%" }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                      <button
                        type="submit"
                        className="move-btn move-btn-su mb-4"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Container>
    </div>
  );
};

export default AddAdminUser;
