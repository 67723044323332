import axios from "axios";
import { PRICElIST_BASE_URL } from "./BaseURL";
import getHeaders from "../constants/headers";

export const getRemarksType = () => {
  return axios({
    method: "get",
    url: PRICElIST_BASE_URL + "/api/secure/v1/admin/garment/remark-type",
    headers: getHeaders(),
  });
};

export const addRemarksType = (data) => {
  return axios({
    method: "post",
    url: PRICElIST_BASE_URL + "/api/secure/v1/admin/garment/remark-type",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};

export const updateRemarksType = (data) => {
  return axios({
    method: "put",
    url: PRICElIST_BASE_URL + "/api/secure/v1/admin/garment/remark-type",
    headers: getHeaders(),
    data: JSON.stringify(data),
  });
};
